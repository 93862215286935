import { get, add, update, archive } from '@/service/api/shops'

export default {
  state: {
    shops: [],
    loadingShops: false
  },
  mutations: {
    setShops(state, shops) {
      state.shops = shops
      state.loadingShops = false
    },
    setLoadingShops(state) {
      state.loadingShops = true
    }
  },
  actions: {
    async getShops({commit}) {
      commit('setLoadingShops')
      const response = await get()    
      if (response.status === 200) {
        commit('setShops', response.data.shops)
      }
    },
    async addShop({commit}, shop) {
      const response = await add(shop)
      if (response.status === 200) {
        commit('setShops', response.data.shops)
      }
    },
    async updateShop({commit}, shop) {
      const response = await update(shop)
      if (response.status === 200) {
        commit('setShops', response.data.shops)
      }
    },
    async archiveShop({commit}, shop) {
      const response = await archive(shop)
      if (response.status === 200) {
        commit('setShops', response.data.shops)
      }
    },
  }
}