import { getFilterItems } from '@/service/api/shops'

export default {
  state: {
    show: false,
    filters: []
  },
  mutations: {
    openDialogFilters(state) {
      state.show = true
    },
    closeDialogFilters(state) {
      state.show = false    
    },
    addFilter(state, filter) {
      state.filters.push(filter)
    },
    removeFilter(state, filter) {
      const index = state.filters.indexOf(state.filters.find(item => item.text === filter.text))
			state.filters.splice(index, 1)
    },
    clearFilters(state) {
      state.filters = []
    }
  },
  actions: {
    async getFilterItems({}, {filterType, shop}) {
      const response = await getFilterItems(filterType, shop)
      return response.data
    }
  }
}