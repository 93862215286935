import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import firebase from 'firebase'
import './style/index.css'

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyDb2_9MUgIjCer5SblHb9ldcFRH2OXqD4k",
  authDomain: "signal-dashboard.firebaseapp.com",
  projectId: "signal-dashboard",
  storageBucket: "signal-dashboard.appspot.com",
  messagingSenderId: "152377476116",
  appId: "1:152377476116:web:2a1ea92785534392ae1301",
  measurementId: "G-EQ7NRZ8WQQ"
};

firebase.initializeApp(firebaseConfig)

let app = ''

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})