<template>
  <div v-if="!overlay">
    <v-layout fluid>
      <v-flex xs6>
        <v-btn rounded color="primary" large :to="'/presentation/detail/' + $route.params.presentationId">
          <v-icon left>mdi-chevron-left</v-icon>
          Back
        </v-btn>
      </v-flex>
      <!-- Save Slide -->
      <v-flex xs12 class="text-right">
        <v-btn rounded color="primary" large @click="saveSlide" :loading="saveProgress">
          Save Changes
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout fluid class="my-10">
      <!-- Slide -->
      <v-flex xs8>
        <!-- <Page/> -->
        <v-sheet color="white" elevation="5" height="900px" class="rounded-lg" outlined rounded shaped width="95%">
          <iframe id="slide" frameBorder="0" scrolling="no" 
          class="rounded-lg" style="width: 100%;height: 900px;" 
          src="/webslides/index.html#slide=1"/>
        </v-sheet>
      </v-flex>
      <!-- Slide Forms -->
      <v-flex xs4>
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- Sequence Slide -->
          <v-text-field v-model="sequence" label="Sequence slide" outlined/>
          <!-- Headline -->
          <v-text-field v-model="headline" label="Headline" outlined/>
          <!-- SubHeadline -->
          <v-textarea v-model="subheadline" label="Sub-headline" auto-grow outlined rows="1"/>
          <!-- Slide Data -->
          <v-divider/>
          <!-- Slide Data -->
          <div class="headline my-6">
            Data
          </div>
          <!-- Start Date -->
          <v-dialog ref="slideDateFrom" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined v-model="dateTextFrom" label="Start Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
            </template>
            <v-date-picker v-model="dateFrom" scrollable no-title color="green lighten-1" :allowed-dates="allowedDates">
              <v-btn text color="primary" @click="modalDateFrom = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.slideDateFrom.save(dateFrom)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <!-- End Date -->
          <v-dialog ref="slideDateTo" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined v-model="dateTextTo" label="End Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details/>
            </template>
            <v-date-picker v-model="dateTo" scrollable no-title color="green lighten-1" :allowed-dates="allowedDates">
              <v-btn text color="primary" @click="modalDateTo = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.slideDateTo.save(dateTo)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <!-- Data Display -->
          <v-radio-group v-model="dataDisplay">
            <template v-slot:label>
              <div>Data Display:</div>
            </template>
            <v-radio value="table">
              <template v-slot:label>
                <div>Table</div>
              </template>
            </v-radio>
            <v-radio value="graph">
              <template v-slot:label>
                <div>Graph</div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-divider/>
          <!-- Notes -->
          <v-textarea class="my-6" v-model="notes" label="Notes" auto-grow outlined rows="5"/>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'Slide',
  data() {
    return {
      valid: false,
      sequence: '',      
      headline: '',
      subheadline: '',
      dateFrom: '',
      dateTo: '',
      dataDisplay: 'table',
      notes: '',
      modalDateFrom: false,
      modalDateTo: false,
      slideLoaded: false,
      saveProgress: false,
      allowedDates: val => {
        let arrayDate = val.split('-')
        let date = new Date([arrayDate[0], arrayDate[1], (arrayDate[2])].join('/'))
        let today = new Date()
        return date < today
      }
    }
  },
  created() {
    this.$store.dispatch('getSlideDetail', this.$route.params.slideId).then(() => {
      this.verifySlideLoadedAndSetContent()
    })
    const date = new Date()
    this.dateFrom = date.getFullYear() + '-' + (date.getMonth() + 1) + '-01'
    this.dateTo = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + new Date().getDate()    
  },
  computed: {
    slideDetail() {
      return this.$store.state.GlobalModules.slides.slideDetail
    },
    dateTextFrom() {
      return this.dateText(this.dateFrom)
    },
    dateTextTo() {
      return this.dateText(this.dateTo)
    },
    overlay() {
      return this.$store.state.GlobalModules.loader.overlay
    }
  },
  methods: {
    dateText(date) {
      let dateSplitted = date.split('-')      
      return dateSplitted[1] + '/' + dateSplitted[2] + '/' + dateSplitted[0]
    },
    verifySlideLoadedAndSetContent() {
      this.slideLoaded = setInterval(() => {
        this.setSlideContent()
      }, 500)
    }, 
    setSlideContent() {
      if (document.getElementById('slide')) {
        this.sequence = this.slideDetail.sequence ? this.slideDetail.sequence : '' 
        this.headline = this.slideDetail.headline ? this.slideDetail.headline : '' 
        this.subheadline = this.slideDetail.subheadline ? this.slideDetail.subheadline : '' 
        this.notes = this.slideDetail.note ? this.slideDetail.note.text : '' 
        this.manipulateHTML('headline', this.headline)
        this.manipulateHTML('subheadline', this.subheadline)
        this.manipulateHTML('notes', this.notes)
        clearInterval(this.slideLoaded)
      }
    },
    manipulateHTML(idElement, text) {
      document.getElementById('slide').contentWindow.document.getElementById(idElement).innerHTML = text
    },
    saveSlide() {
      const slideData = {
        slideId: this.$route.params.slideId,
        headline: this.headline,
        subheadline: this.subheadline,
        noteText: this.notes,
        sequence: this.sequence
      }
      this.saveProgress = true
      this.$store.dispatch('updateSlide', slideData).then(() => {
        this.saveProgress = false
      })
    }
  },
  watch: {
    headline() {
      this.manipulateHTML('headline', this.headline)
    },
    subheadline() {
      this.manipulateHTML('subheadline', this.subheadline)
    },
    notes() {
      this.manipulateHTML('notes', this.notes)
    }
  }
}
</script>