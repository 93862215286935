import { getAllAnalytics } from '@/service/api/analytics'

export default {
  state: {
    analytics: {
      data_summary: [],
      data: []
    }
  },
  mutations: {
    setAnalytics(state, analytics) {
      state.analytics = analytics
    },
    clearAnalytics(state) {
      state.analytics = {
        data_summary: [],
        data: []
      }
    }
  },
  actions: {
    async getAllAnalytics({commit}, {load, dateRange, shop, filters}) {
      commit('setLoader', load)
      const response = await getAllAnalytics(dateRange, shop, filters)
      if (!response) {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
      if (response.status === 200) {
        commit('setAnalytics', response.data)
      } else {
        commit('openSnackbar', {message: response.data.message, timeout: 6000})
      }
      commit('setLoader', false)
    }
  }
}