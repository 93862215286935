import firebase from 'firebase'
import axios from 'axios'
import { presentations, newPresentation } from "@/config/api"

// Get Presentations
export const getPresentations = async (userId) => {
  try {
    const response = await axios.get(`${presentations}?userId=${firebase.auth().currentUser.uid}`)
    return response.data
  } catch (error) {
    return error
  }
}

// Create new Presentation
export const createNewPresentation = async (presentation) => {
  try {
    const response = await axios.post(`${newPresentation}`, JSON.stringify(presentation))
    return response.data
  } catch (error) {
    return error
  }
}