<template>
  <div v-if="!overlay" fluid>
    <!-- New Shop -->
    <v-layout fluid>
      <v-flex xs12 class="add-shop">
        <v-btn rounded color="primary" large @click="openDialogAddShop">
          + Add Shop
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Data -->
    <v-data-table :loading="loading" :headers="headers" :items="shops" :page.sync="page" no-data-text="No data"
      :items-per-page="rowsPerPageSelected" class="elevation-0" hide-default-footer>
      <template v-slot:item.actions="{item}">
        <!-- Edit Shop -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" @click="openDialogAddShop(item)" v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <!-- Archive Shop -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" @click="archiveShop(item)" v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Archive</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- No data found -->
    <div v-if="shops.length === 0" class="no-data">
      No data found
    </div>
    <!-- Pagination -->
    <v-pagination class="pagination" :disabled="loading || shops.length === 0" v-model="page" :total-visible="7" :length="pageCount"/>
    <!-- Dialog Archive Shop -->
    <DialogAddShop/>
    <!-- Dialog Archive Shop -->
    <DialogArchiveShop/>
  </div>
</template>

<script>
import DialogAddShop from '@/components/views/shops/dialogAddShop'
import DialogArchiveShop from '@/components/views/shops/dialogArchiveShop'

export default {
  name: 'Shops',
  components: { DialogAddShop, DialogArchiveShop },
  data() {
    return {
      loading: false,
      page: 1,
      rowsPerPageSelected: 50,
      rowsPerPage: [25, 50, 100],
      headers: [
        { 
          text: 'Shop', 
          align: 'left',
          value: 'shop',
          sortable: false
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
    }
  },
  created() {
    this.$store.dispatch('getShops')
  },
  computed: {
    overlay() {
      return this.$store.state.GlobalModules.loader.overlay
    },
    shops() {
      return this.$store.state.GlobalModules.shops.shops
    },
    pageCount() {
      let page = this.shops.length > 0 ?
        this.shops.length / this.rowsPerPageSelected : 1
      return page < 1 ? 1 : Math.ceil(page)
    }
  },
  methods: {
    openDialogAddShop(shop) {
      let data = {
        id: shop.id,
        shop: shop.id ? shop.shop.replace('.myshopify.com', '') : ''
      }
      this.$store.commit('openDialogAddShop', data)
    },
    archiveShop(shop) {
      this.$store.commit('openDialogArchiveShop', shop)
    }
  }
}
</script>

<style scoped src='./style/index.css'/>