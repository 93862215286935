<template>
  <v-dialog v-model="dialog.show" max-width="500">
    <v-card>
      <v-card-title class="headline">
        Do you want to archive this Shop?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          No
        </v-btn>
        <v-btn color="primary" @click="stopImport">
          <v-progress-circular color="white" v-if="progress" indeterminate></v-progress-circular>
          <span v-else>Yes</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogArchiveShop',
  data() {
    return {
      progress: false
    }
  },
  computed: {
    dialog() {
      return this.$store.state.GlobalModules.dialogArchiveShop
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit('closeDialogArchiveShop')
    },
    stopImport() {
      this.progress = true
      this.$store.dispatch('archiveShop', this.dialog.shop).then(() => {
        this.progress = false
        this.closeDialog()
      })
    }
  }
}
</script>