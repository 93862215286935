<template>
  <div>
    <v-form ref="form" v-model="valid" @keyup.native.enter="search" lazy-validation>
      <v-layout fluid>
        <!-- Order Id -->
        <v-flex xs6 class="mx-4">
          <v-text-field label="Search Order Id" outlined v-model="orderId" 
            :rules="rules.orderId" clearable/>
        </v-flex>
        <!-- Search Order -->
        <v-btn rounded color="primary" large @click="search" :disabled="progress" class="search">
          <v-progress-circular color="white" v-if="progress" indeterminate></v-progress-circular>
          <span v-else class="text-white">Search</span>
        </v-btn>
      </v-layout>
      <div v-if="orders.length > 0">
        <div class="order-detail" v-for="order in orders" :key="order._id">
          Order Id: {{order.order_id === '' ? '-' : order.order_id}}
          <br>
          <br>
          Order Name: {{order.order_name === '' ? '-' : order.order_name}}
          <br>
          Date Added: {{order.hour === '' ? '-' : order.hour}}
          <br>
          Customer Id: {{order.customer_id === '' ? '-' : order.customer_id}}
          <br>
          Customer Type: {{order.customer_type === '' ? '-' : order.customer_type}}
          <br>
          Gross Revenue: {{order.gross_sales === '' ? '-' : order.gross_sales}}
          <br>
          Net Revenue: {{order.net_sales === '' ? '-' : order.net_sales}}
          <br>
          Refund Amount: {{order.returns === '' ? '-' : order.returns}}
          <br>
          Discounts: {{order.discounts === '' ? '-' : order.discounts}}
          <br>
          Discount Code: {{order.discount_code === '' ? '-' : order.discount_code}}
          <br>
          Product Title: {{order.product_title === '' ? '-' : order.product_title}}
          <br>
          Product Id: {{order.product_id === '' ? '-' : order.product_id}}
          <br>
          Referring Site: {{order.referrer_url === '' ? '-' : order.referrer_url}}
          <br>
          City: {{order.shipping_city === '' ? '-' : order.shipping_city}}
          <br>
          Region: {{order.shipping_region === '' ? '-' : order.shipping_region}}
          <br>
          Country: {{order.shipping_country === '' ? '-' : order.shipping_country}}
          <!-- <br> -->
          <!-- Refund: {{order.utm_campaign_source === '' ? '-' : order.utm_campaign_source}} -->
          <br>
          UTM Campaign: {{order.utm_campaign_name === '' ? '-' : order.utm_campaign_name}}
          <br>
          UTM Medium: {{order.utm_campaign_medium === '' ? '-' : order.utm_campaign_medium}}
          <br>
          UTM Content: {{order.utm_campaign_content === '' ? '-' : order.utm_campaign_content}}
          <br>
          UTM Source: {{order.utm_campaign_source === '' ? '-' : order.utm_campaign_source}}
          <br>
          UTM Term: {{order.utm_campaign_term === '' ? '-' : order.utm_campaign_term}}
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  data() {
    return {
      valid: false,
      progress: false,
      orderId: '',
      rules: {
        orderId: [
          v => !!v || 'Order Id is required!'
        ]
      }
    }
  },
  created() {
    this.$store.dispatch('getShops')
    this.$store.commit('setOrder', [])
    const url = new URL(document.location.href)
    this.orderId = url.searchParams.get('orderId')
    if (this.shop === '') {
      this.$store.commit('setGlobalSelectShop', url.searchParams.get('shop') ? url.searchParams.get('shop') : '')
    }
    if (this.orderId && this.shop) {
      this.progress = true
      this.$store.dispatch('getOrder', {orderId: this.orderId, shop: this.shop}).then(() => {
        this.progress = false
      })
    }
  },
  computed: {
    orders() {
      return this.$store.state.GlobalModules.orders.order
    },
    shops() {
      return this.$store.state.GlobalModules.shops.shops
    },
    shop: {
      get: function() {
        return this.$store.state.GlobalModules.globalSelectShop.shop
      },
      set: function(newShop) {
        this.$store.commit('setGlobalSelectShop', newShop)
      }
    },
  },
  methods: {
    search() {
      if (this.$refs.form.validate()) {
        this.progress = true
        this.$store.dispatch('getOrder', {orderId: this.orderId, shop: this.shop}).then(() => {
          this.progress = false
        })
      }
    },
    setFilter() {
      let filter = '?shop=' + this.shop + '&orderId=' + this.orderId
      if (this.orderId == null || this.shop == null) {
        this.$store.commit('setOrder', [])
        filter = ''
        return
      }
      const setOrderId = window.location.protocol + "//" + window.location.host + window.location.pathname + filter
      window.history.pushState({ path: setOrderId }, '', setOrderId)
    }
  },
  watch: {
    shop() {
      this.setFilter()
    },
    orderId() {
      this.setFilter()
    }
  }
}
</script>

<style scoped src='./style/index.css'/>