import firebase from 'firebase'
import { importFileS3 } from '@/service/s3-bucket'
import { importFileAPI, stopImportQueue } from '@/service/api/import-file'

export default {
  state: {
    rowsCount: 0
  },
  mutations: {
    setRowsCount(state, data) {
      state.rowsCount = data
    }
  },
  getters: {
    getRowsCount(state){
      return state.rowsCount
    }
  },
  actions: {
    async importFile({commit, getters}, {file, typeUpload, shop}) {   
      let response = false   
      const responseS3 = await importFileS3(file)
      if (responseS3.key) {
        const data = {
          shop: shop,
          accessToken: firebase.auth().currentUser.uid,
          fileName: file.name,
          fileURL: responseS3.location,
          // recordCount: getters.getRowsCount,
          fileType: typeUpload.toLowerCase()
        }
        const responseAPI = await importFileAPI(data)
        response = responseAPI.data.message === 'SUCCESS'
        if (response) {
          commit('openSnackbar', {message: 'File imported successfully!', timeout: 6000})
        } else {
          commit('openSnackbar', {message: 'Something get wrong, try again!', timeout: 6000})     
        }
        commit('setRowsCount', 0)
      } else {
        commit('openSnackbar', {message: 'Something get wrong, try again!', timeout: 6000})     
      }
      return response
    },
    async stopImport({commit}, {shop, queueId}) {
      const response = await stopImportQueue(shop, queueId)
      if (response) {
        commit('openSnackbar', {message: 'File import cancelled successfully!', timeout: 6000})
      } else {
        commit('openSnackbar', {message: 'Something get wrong, try again!', timeout: 6000})     
      }
    }
  }
}