export default {
  state: {
    show: false
  },
  mutations: {
    openDialogNewPresentation(state) {
      state.show = true
    },
    closeDialogNewPresentation(state) {
      state.show = false    
    }
  }
}