import firebase from 'firebase'
import axios from 'axios'
import { getAnalytics } from "@/config/api"

// Get Analytics
export const getAllAnalytics = async (dateRange, shop, filters) => {
  try {
    let filter = [{type: 'utm_source', value:''}, {type: 'utm_campaign', value:''},
      {type: 'utm_medium', value:''}, {type: 'utm_content', value:''}, {type: 'discount_code', value:''}, 
      {type: 'state', value:''}, {type: 'isDiscountUsed', value:''}, {type: 'country', value:''}]
    let finalFilter = ''
    if (filters.length > 0) {
      filters.filter(element => {
        if (element.type === 'orderValue') {
          let value = element.value.split(' than ')
          finalFilter += '&' + (value[0] === 'more' ? 'orderValueFrom' : 'orderValueTo') + '=' + value[1]
        } else {
          filter.map(item => {
            if (item.type == element.type) {
              item.value += element.value + ','
            }
            return item
          })
        }
      })
      // All filters
      filter.filter(element => {
        if (element.value != '') {
          let value = '' + element.value.slice(0,-1)
          finalFilter += '&' + element.type + '=' + value
        }
      })
    }
    const response = await axios.get(`${getAnalytics}?accessToken=${firebase.auth().currentUser.uid}
      &shop=${shop}&from=${dateRange.from}&to=${dateRange.to}&type=${dateRange.viewBy.toLowerCase()}&page=${dateRange.page}&limit=${dateRange.rowsPerPageSelected}${finalFilter}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return response
  } catch (error) {
    return error.response
  }
}