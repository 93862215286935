import { getSlidesFromAPresentation, getSlideDetail, createNewSlide, updateSlideData } from '@/service/api/slides'

export default {
  state: {
    slides: [],
    loading: false,
    slideDetail: {}
  },
  mutations: {
    setSlides(state, {slides, loading}) {
      state.slides = slides
      state.loading = loading
    },
    setSlideDetail(state, slideDetail) {
      state.slideDetail = slideDetail
    },
    addSlide(state, slide) {
      state.slides.data.push(slide)
    }
  },
  actions: {
    async getSlidesFromAPresentation({commit}, presentationId) {
      commit('setSlides', {slides: [], loading: true})
      const response = await getSlidesFromAPresentation(presentationId)
      if (!response) {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
      if (response.message === 'SUCCESS') {
        commit('setSlides', {slides: response, loading: false})
      } else {
        commit('openSnackbar', {message: response.message, timeout: 6000})
      }
    },
    async getSlideDetail({commit}, slideId) {
      commit('setLoader', true)
      commit('setSlideDetail', [])
      const response = await getSlideDetail(slideId)
      if (!response) {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
      if (response.message === 'SUCCESS') {
        commit('setSlideDetail', response.data)
      } else {
        commit('openSnackbar', {message: response.message, timeout: 6000})
      }
      commit('setLoader', false)
    },
    async createNewSlide({commit}, slide) {
      const response = await createNewSlide(slide)
      if (response.message === 'SUCCESS') {
        commit('addSlide', response.data)
        return commit('openSnackbar', {message: 'Slide created successfully!', timeout: 6000})     
      } else {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
    },
    async updateSlide({commit}, slide) {
      const response = await updateSlideData(slide)
      if (response.message === 'SUCCESS') {
        return commit('openSnackbar', {message: 'Slide updated successfully!', timeout: 6000})     
      } else {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
    }
  }
}