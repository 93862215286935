var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"fluid":""}},[_c('v-flex',{staticClass:"add-presentation",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"rounded":"","color":"primary","large":""},on:{"click":function($event){return _vm.$store.commit('openDialogNewPresentation')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Presentation ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.presentations,"page":_vm.page,"no-data-text":"No data","items-per-page":25,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.slideCounts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.slideCounts ? item.slideCounts : 0)+" ")]}},{key:"item.dateAdded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.dateAdded))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.lastUpdated))+" ")]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","to":'/presentation/detail/' + item._id}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}}])}),_c('v-pagination',{staticClass:"pagination",attrs:{"disabled":_vm.loading || _vm.presentations.length === 0,"total-visible":7,"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('DialogNewPresentation')],1)}
var staticRenderFns = []

export { render, staticRenderFns }