import firebase from 'firebase'
import axios from 'axios'
import { breakdowns } from "@/config/api"

// Get Breakdowns
export const getBreakdowns = async (startDate, endDate, breakdown, count, page, shop) => {
  try {
    const response = await axios.get(`${breakdowns}?start=${startDate}&end=${endDate}&type=${breakdown}&count=${count}&page=${page}&shop=${shop}`)
    return response
  } catch (error) {
    return error
  }
}