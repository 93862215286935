export default {
  state: {
    show: false,
    queueId: ''
  },
  mutations: {
    openDialogConfirmStopImports(state, queueId) {
      state.show = true
      state.queueId = queueId
    },
    closeDialogConfirmStopImports(state) {
      state.show = false    
      state.queueId = ''
    }
  }
}