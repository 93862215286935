export default {
  state: {
    shop: '',
    isSynced: true
  },
  mutations: {
    setGlobalSelectShop(state, shop) {
      state.shop = shop
    },
    setIsSynced(state, isSynced) {
      state.isSynced = isSynced
    }
  }
}
