//const appApi = 'https://sessmt5qbk.execute-api.us-east-1.amazonaws.com/dev'
const appApi = 'https://66srrs2prf.execute-api.us-east-1.amazonaws.com/prod'
//const appApi = 'http://localhost:3000'
// Import Files
export const importFile = `${appApi}/import-queue/create`
export const stopImport = `${appApi}/import-queue/stop`
export const pendingImports = `${appApi}/import-queue/list`

// Orders
export const getOrderById = `${appApi}/orders/list/id`

// Analytics
export const getAnalytics = `${appApi}/analytics/shop`

// Shop
export const addShop = `${appApi}/shop/create`
export const updateShop = `${appApi}/shop/update`
export const archiveShop = `${appApi}/shop/archive`
export const getAllShops = `${appApi}/shop/read`
export const getFilters = `${appApi}/shop/filters`
export const syncStatus = `${appApi}/sync/status`

// Breakdowns
export const breakdowns = `${appApi}/shop/breakdown`

// Presentations
export const presentations = `${appApi}/slide/collection/list`
export const newPresentation = `${appApi}/slide/collection/create`

// Slides
export const slidesFromAPresentation = `${appApi}/slide/collection/slides`
export const slideDetail = `${appApi}/slide/details`
export const newSlide = `${appApi}/slide/create`
export const updateSlide = `${appApi}/slide/update`

// User
export const user = `${appApi}/user`
export const userSignUp = `${appApi}/user/signup`
export const saveShopifyTokenAPI = `${appApi}/token/attach`