export default {
  state: {
    show: false,
    shop: {}
  },
  mutations: {
    openDialogArchiveShop(state, shop) {
      state.show = true
      state.shop = shop
    },
    closeDialogArchiveShop(state) {
      state.show = false    
      state.shop = {}
    }
  }
}