import { getPresentations, createNewPresentation } from '@/service/api/presentations'

export default {
  state: {
    presentations: [],
    loading: true
  },
  mutations: {
    setPresentations(state, presentations) {
      state.presentations = presentations
      state.loading = false
    },
    setPresentationsLoading(state, loading) {
      state.loading = loading
    },
    addPresentation(state, presentation) {
      state.presentations.push(presentation)
    }
  },
  actions: {
    async getPresentations({commit}, userId) {
      commit('setPresentationsLoading', true)
      const response = await getPresentations(userId)
      if (!response) {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
      if (response.message === 'SUCCESS') {
        commit('setPresentations', response.data)
      } else {
        commit('openSnackbar', {message: response.message, timeout: 6000})
      }
    },
    async createNewPresentation({commit}, presentation) {
      const response = await createNewPresentation(presentation)
      if (response.message === 'SUCCESS') {
        commit('addPresentation', response.data)
        return commit('openSnackbar', {message: 'Presentation created successfully!', timeout: 6000})     
      } else {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
    }
  }
}