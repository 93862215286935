<template>
  <div>
    <v-layout fluid>
      <v-flex xs6>
        <v-btn rounded color="primary" large to="/presentation/list">
          <v-icon left>mdi-chevron-left</v-icon>
          Back
        </v-btn>
      </v-flex>
      <!-- New Slide -->
      <v-flex xs12 class="add-slide">
        <v-btn rounded color="primary" large @click="$store.commit('openDialogNewSlide')">
          <v-icon left>mdi-plus</v-icon>
          New Slide
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Slide Title -->
    <div class="headline mx-2 my-4" v-if="!loading">
      {{slides.collection.title}}
    </div>
    <!-- Data -->
    <v-data-table :loading="loading" :headers="headers" :items="slides.data" :page.sync="page" no-data-text="No data"
      :items-per-page="25" class="elevation-0" hide-default-footer>
      <!-- Date Added -->
      <template v-slot:item.dateAdded="{item}">
        {{convertDate(item.dateAdded)}}
      </template>
      <!-- Last Update -->
      <template v-slot:item.lastUpdated="{item}">
        {{convertDate(item.lastUpdated)}}
      </template>
      <!-- Position -->
      <template v-slot:item.sequence="{item}">
        {{item.sequence ? item.sequence : '-'}}
      </template>
      <!-- View Slide -->
      <template v-slot:item.view="{item}">        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" v-bind="attrs" v-on="on" :to="'/presentation/detail/' + $route.params.presentationId + '/slide/' + item._id">
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- Pagination -->
    <v-pagination class="pagination" :disabled="loading || slides.length === 0" v-model="page" :total-visible="7" :length="pageCount"/>
    <!-- Dialog New Slide -->
    <DialogNewSlide/>
  </div>
</template>

<script>
import { convertTimestampToDate } from '@/utils/date'

export default {
  name: 'Slides',
  components: {
    DialogNewSlide: () => import('@/components/views/slides/dialogNewSlide'),
  },
  data() {
    return {
      page: 1,      
      headers: [
        { 
          text: 'Title', 
          align: 'left',
          value: 'headline',
          sortable: false
        },
        { 
          text: 'Date Created', 
          align: 'center',
          value: 'dateAdded',
          sortable: false
        },
        { 
          text: 'Last Updated', 
          align: 'center',
          value: 'lastUpdated',
          sortable: false
        },
        { 
          text: 'Postion', 
          align: 'center',
          value: 'sequence',
          sortable: false
        },
        {
          text: 'View',
          align: 'center',
          value: 'view',
          sortable: false
        }
      ],
    }
  },
  created() {    
    this.$store.dispatch('getSlidesFromAPresentation', this.$route.params.presentationId)
  },
  computed: {
    slides() {
      return this.$store.state.GlobalModules.slides.slides
    },
    loading() {
      return this.$store.state.GlobalModules.slides.loading
    },
    pageCount() {
      let page = this.slides.length > 0 ?
        this.slides.length / this.rowsPerPageSelected : 1
      return page < 1 ? 1 : Math.ceil(page)
    }
  },
  methods: {
    convertDate(date) {
      return convertTimestampToDate(date)
    }
  }
}
</script>

<style scoped src='./style/index.css'/>