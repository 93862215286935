export default {
  data: [
    { 
      'text': 'UTM Campaign',
      'value': 'utm_campaign'
    }, 
    { 
      'text': 'UTM Source',
      'value': 'utm_source'
    }, 
    { 
      'text': 'UTM Medium',
      'value': 'utm_medium'
    }, 
    { 
      'text': 'UTM Content',
      'value': 'utm_content'
    }, 
    { 
      'text': 'State/Province',
      'value': 'state'
    }, 
    { 
      'text': 'Country',
      'value': 'country'
    }, 
    { 
      'text': 'Order Value',
      'value': 'orderValue'
    }, 
    { 
      'text': 'Discount Code',
      'value': 'discount_code'
    },
    { 
      'text': 'Is Discount Used?',
      'value': 'isDiscountUsed'
    }
  ]
}