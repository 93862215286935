import Vue from 'vue'
import store from '@/store'
import firebase from 'firebase'
import VueRouter from 'vue-router'
import SignIn from '@/views/app/SignIn/index.vue'
import SignUp from '@/views/app/SignUp/index.vue'
import ResetPassword from '@/views/app/ResetPassword/index.vue'
import Import from '@/views/app/Import/index.vue'
import ImportHistory from '@/views/app/ImportHistory/index.vue'
import Analytics from '@/views/app/Analytics/index.vue'
import Orders from '@/views/app/Orders/index.vue'
import Shops from '@/views/app/Shops/index.vue'
import Breakdowns from '@/views/app/Breakdowns/index.vue'
import PresentationsRouterView from '@/views/app/Presentation/index.vue'
import PresentationList from '@/views/app/Presentation/List/index.vue'
import PresentationDetail from '@/views/app/Presentation/Detail/index.vue'
import PresentationSlide from '@/views/app/Presentation/Slide/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'SignIn',
    component: SignIn,
    meta: {
      title: 'Sign In',
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      title: 'Sign Up',
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
    }
  },
  // {
  //   path: '/import',
  //   name: 'Import',
  //   component: Import,
  //   meta: {
  //     title: 'Import Files',
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/import/history',
  //   name: 'ImportHistory',
  //   component: ImportHistory,
  //   meta: {
  //     title: 'Import History',
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: {
      title: 'Analytics',
      requiresAuth: true
    }
  },
  // {
  //   path: '/orders',
  //   name: 'Orders',
  //   component: Orders,
  //   meta: {
  //     title: 'Orders',
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/shops',
  //   name: 'Shops',
  //   component: Shops,
  //   meta: {
  //     title: 'Shops',
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/breakdowns',
    name: 'Breakdowns',
    component: Breakdowns,
    meta: {
      title: 'Breakdowns',
      requiresAuth: true
    }
  },
  // {
  //   path: '/presentation',
  //   name: 'PresentationsRouterView',
  //   component: PresentationsRouterView,
  //   redirect: '/presentation/list',
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'PresentationList',
  //       component: PresentationList,
  //       meta: {
  //         title: 'Presentations',
  //         requiresAuth: true,
  //       }
  //     },
  //     {
  //       path: 'detail/:presentationId',
  //       name: 'PresentationDetail',
  //       component: PresentationDetail,
  //       meta: {
  //         title: 'Presentation Slides',
  //         requiresAuth: true,
  //       }
  //     },
  //     {
  //       path: 'detail/:presentationId/slide/:slideId',
  //       name: 'PresentationSlide',
  //       component: PresentationSlide,
  //       meta: {
  //         title: 'Presentation Slide',
  //         requiresAuth: true,
  //       }
  //     },
  //   ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.query.token) {
    localStorage.setItem('shopify', to.query.token)
  }  
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (currentUser) { store.commit('setIsAuthenticated', true) }
  
  if (requiresAuth && !currentUser) {
    return next({ path: '/'})
  } else if (!requiresAuth && currentUser) {
    return next({ path: '/analytics'})
  } else {
    return next()
  }
})

export default router