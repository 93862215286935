import axios from 'axios'
import firebase from 'firebase'
import { addShop, updateShop, archiveShop, getAllShops, getFilters, syncStatus } from "@/config/api"

// Get AllShops
export const get = async () => {
  try {
    const response = await axios.get(`${getAllShops}?userId=${firebase.auth().currentUser.uid}`)
    return response
  } catch (error) {
    return error
  }
}

// Add New Shop
export const add = async (shop) => {
  try {
    const response = await axios.post(`${addShop}`, JSON.stringify(shop))
    return response
  } catch (error) {
    return error
  }
}

// Update a Shop
export const update = async (shop) => {
  try {
    console.log(shop)
    const response = await axios.post(`${updateShop}`, JSON.stringify(shop))
    return response
  } catch (error) {
    return error
  }
}

// Archive a Shop
export const archive = async (shop) => {
  try {
    const response = await axios.post(`${archiveShop}`, JSON.stringify(shop))
    return response
  } catch (error) {
    return error
  }
}

// Get Filters Items
export const getFilterItems = async (filterType, shop) => {
  try {
    const response = await axios.get(`${getFilters}?type=${filterType}&shop=${shop}`)
    return response
  } catch (error) {
    return error
  }
}

// Get Filters Items
export const getShopSyncStatus = async (shop) => {
  try {
    const response = await axios.get(`${syncStatus}?shop=${shop}`)
    return response.data
  } catch (error) {
    return error
  }
}