var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.overlay)?_c('div',{staticStyle:{"display":"none"},attrs:{"fluid":""}},[_c('v-layout',{attrs:{"fluid":""}},[_c('v-flex',{staticClass:"mx-4",attrs:{"xs4":""}},[_c('v-select',{attrs:{"items":_vm.breakdownItems.data,"item-text":"text","item-value":"value","label":"Breakdown","outlined":""},model:{value:(_vm.breakdown),callback:function ($$v) {_vm.breakdown=$$v},expression:"breakdown"}})],1),_c('v-flex',{staticClass:"mx-4",attrs:{"xs2":""}},[_c('v-dialog',{ref:"dialogStartDate",attrs:{"return-value":_vm.startDate,"width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateTextStart),callback:function ($$v) {_vm.dateTextStart=$$v},expression:"dateTextStart"}},'v-text-field',attrs,false),on))]}}],null,false,1363908185),model:{value:(_vm.modalStartDate),callback:function ($$v) {_vm.modalStartDate=$$v},expression:"modalStartDate"}},[_c('v-date-picker',{attrs:{"scrollable":"","no-title":"","color":"light-blue lighten-1","allowed-dates":_vm.allowedDates},on:{"input":function($event){return _vm.$refs.dialogStartDate.save(_vm.startDate)}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-flex',{staticClass:"mx-4",attrs:{"xs2":""}},[_c('v-dialog',{ref:"dialogEndDate",attrs:{"return-value":_vm.endDate,"width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateTextEnd),callback:function ($$v) {_vm.dateTextEnd=$$v},expression:"dateTextEnd"}},'v-text-field',attrs,false),on))]}}],null,false,1215317465),model:{value:(_vm.modalEndDate),callback:function ($$v) {_vm.modalEndDate=$$v},expression:"modalEndDate"}},[_c('v-date-picker',{attrs:{"scrollable":"","no-title":"","color":"light-blue lighten-1","allowed-dates":_vm.allowedDates},on:{"input":function($event){return _vm.$refs.dialogEndDate.save(_vm.endDate)}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-flex',{staticClass:"mx-4",attrs:{"xs1":""}},[_c('v-select',{attrs:{"items":_vm.rowsPerPage,"label":"Rows Per Page","outlined":""},model:{value:(_vm.rowsPerPageSelected),callback:function ($$v) {_vm.rowsPerPageSelected=$$v},expression:"rowsPerPageSelected"}})],1),_c('v-flex',{staticClass:"mx-4 text-right",attrs:{"xs2":""}},[_c('v-btn',{staticClass:"filter-btn",attrs:{"depressed":"","color":"primary","id":"search","disabled":_vm.loading,"large":""},on:{"click":_vm.search}},[_vm._v(" Search ")])],1)],1),_c('v-data-table',{staticClass:"elevation-0 row-pointer",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.breakdowns.data,"page":_vm.page,"no-data-text":"No data","items-per-page":_vm.rowsPerPageSelected,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.to,on:{"click":function($event){return _vm.onClickRow(_vm.breakdown, item)}}},[_c('td',{staticClass:"primary--text"},[_vm._v(_vm._s(item[_vm.breakdown] ? item[_vm.breakdown] : '-'))]),_c('td',[_vm._v(_vm._s(item.total_net_revenue != null ? _vm.formatCurrencyValues(item.total_net_revenue) : '-'))]),_c('td',[_vm._v(_vm._s(item.order_count != null ? item.order_count : '-'))]),_c('td',[_vm._v(_vm._s(item.new_aov != null ? _vm.formatCurrencyValues(item.new_aov) : '-'))]),_c('td',[_vm._v(_vm._s(item.new_net_revenue != null ? _vm.formatCurrencyValues(item.new_net_revenue) : '-'))]),_c('td',[_vm._v(_vm._s(item.repeat_order_count != null ? item.repeat_order_count : '-'))]),_c('td',[_vm._v(_vm._s(item.repeat_aov != null ? _vm.formatCurrencyValues(item.repeat_aov) : '-'))]),_c('td',[_vm._v(_vm._s(item.repeat_net_revenue != null ? _vm.formatCurrencyValues(item.repeat_net_revenue) : '-'))]),_c('td',[_vm._v(_vm._s(item.total_cogs != null ? _vm.formatCurrencyValues(item.total_cogs) : '-'))]),_c('td',[_vm._v(_vm._s(item.takehome_net_revenue != null ? _vm.formatCurrencyValues(item.takehome_net_revenue) : '-'))]),_c('td',[_vm._v(_vm._s(item.takehome_net_revenue != null ? _vm.formatCurrencyValues(item.takehome_net_revenue) : '-'))]),_c('td',[_vm._v(_vm._s(item.takehome_net_revenue != null ? _vm.formatCurrencyValues(item.takehome_net_revenue) : '-'))])])}),0)]}}],null,false,3568888614)}),(_vm.breakdowns.data.length === 0)?_c('div',{staticClass:"no-data"},[_vm._v(" No data found ")]):_vm._e(),_c('v-pagination',{attrs:{"disabled":_vm.loading || _vm.breakdowns.data.length === 0,"total-visible":7,"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }