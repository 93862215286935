export const convertTimestampToDate = (timestamp) => {
  return getFullDate(new Date(Number(timestamp)))
}

export const getFullDate = (date) => {
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear(),
    hours = date.getHours() % 12,
    minutes = date.getMinutes(),
    ampm = hours >= 12 ? 'pm' : 'am',
    hour = (hours ? hours : 12) + ':' + ((minutes < 10 ? '0' : '') + minutes) + ' ' + ampm
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [month, day, year].join('/') + ' ' +  hour
}