import firebase from 'firebase'
import axios from 'axios'
import { slidesFromAPresentation, slideDetail, newSlide, updateSlide } from "@/config/api"

// Get Slides From a Presentation
export const getSlidesFromAPresentation = async (presentationId) => {
  try {
    const response = await axios.get(`${slidesFromAPresentation}?collectionId=${presentationId}`)
    return response.data
  } catch (error) {
    return error
  }
}

// Get Slide Detail
export const getSlideDetail = async (slideId) => {
  try {
    const response = await axios.get(`${slideDetail}?slideId=${slideId}&authToken=${firebase.auth().currentUser.uid}`)
    return response.data
  } catch (error) {
    return error
  }
}

// Create new Slide
export const createNewSlide = async (slide) => {
  try {
    const response = await axios.post(`${newSlide}`, JSON.stringify(slide))
    return response.data
  } catch (error) {
    return error
  }
}

// Update Slide
export const updateSlideData = async (slide) => {
  try {
    const response = await axios.post(`${updateSlide}`, JSON.stringify(slide))
    return response.data
  } catch (error) {
    return error
  }
}