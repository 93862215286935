export default {
  state: {
    show: false
  },
  mutations: {
    openDialogNewSlide(state) {
      state.show = true
    },
    closeDialogNewSlide(state) {
      state.show = false    
    }
  }
}