import firebase from 'firebase'

// Register user in firebase
export const signUp = async (user) => {
  try {
    const response = await firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
    firebase.auth().currentUser.sendEmailVerification()
    firebase.auth().signOut()
    return response
  } catch (error) {
    return error
  }
}

// Sign In user with firebase
export const signIn = async (user) => {
  try {
    const response = await firebase.auth().signInWithEmailAndPassword(user.email, user.password)
    return response
  } catch (error) {
    return error
  }
}

// Log out user from firebase
export const signOut = async () => {
  try {
    const response = await firebase.auth().signOut()
    return response
  } catch (error) {
    return error
  }
}

// Verify Email
export const verifyEmail = async (actionCode) => {
  try {
    const response = await firebase.auth().applyActionCode(actionCode)
    return response
  } catch (error) {
    return error
  }
}

// Recovery Link
export const recoveryLink = async (email) => {
  try {
    const response = await firebase.auth().sendPasswordResetEmail(email)
    return response
  } catch (error) {
    return error
  }
}

// Verify Recovery Link
export const verifyRecoveryLink = async (actionCode) => {
  try {
    const response = await firebase.auth().verifyPasswordResetCode(actionCode)
    return response
  } catch (error) {
    return error
  }
}

// Reset Password
export const resetPassword = async (data) => {
  try {
    const response = await firebase.auth().confirmPasswordReset(data.token, data.password)
    return response
  } catch (error) {
    return error
  }
}