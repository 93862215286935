<template>
  <v-main id="main">
    <AppToolbar v-if="isAuthenticated"/>
    <transition name="fade" mode="out-in">
      <router-view style="padding: 30px 20px"/>
    </transition>
    <Snackbar/>
    <Loader/>
  </v-main>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Body',
  components: {
    AppToolbar: () => import('@/components/app/Toolbar'),
    Snackbar: () => import('@/components/Snackbar'),
    Loader: () => import('@/components/app/Loader'),
  },
  computed: {
    isAuthenticated() {
      let isAuthenticated = this.$store.state.GlobalModules.auth.isAuthenticated
      if (isAuthenticated) {
        this.$store.dispatch('getShops')
        if (localStorage.getItem('shopify')) {
          const shopifyToken = {
            token: localStorage.getItem('shopify'),
            authToken: firebase.auth().currentUser.uid,
            userId: firebase.auth().currentUser.uid
          }
          this.$store.dispatch('saveShopifyToken', shopifyToken).then(() => {          
            localStorage.removeItem('shopify')
            this.$store.dispatch('getUser')
          })
        }
      }
      return isAuthenticated
    }
  }
}
</script>