<template>
  <v-container class="fill-height reset-password-card" fluid>
    <v-row justify="center">
      <v-flex>
        <v-card class="mx-auto elevation-0" max-width="400">
          <!-- Logo -->
          <v-card-title class="logo">
            <img src="@/assets/logos/logo.png" height="100">
          </v-card-title>
          <!-- Page Info -->
          <div class="row justify-center reset-password-info">     
            <span v-show="!isRecover">Enter email to reset password.!</span>
            <span v-show="isRecover">Update your password!</span>
          </div>
          <!-- Form -->
          <v-form class="form" ref="form" v-model="valid" @keyup.native.enter="submit" lazy-validation>
            <!-- User -->
            <v-text-field label="E-mail" 
              v-show="!isRecover"
              v-model="resetPassword.email"
              :rules="rules.email"
              solo single-line prepend-inner-icon="mdi-account" required/>
            <!-- Password -->
            <v-text-field label="New Password" 
              v-show="isRecover"
              v-model="resetPassword.password"
              :rules="rules.password"
              :append-icon="invisibility ? 'mdi-eye-off' : 'mdi-eye'"
              :type="invisibility ? 'password' : 'text'" 
              @click:append="() => (invisibility = !invisibility)"
              solo single-line prepend-inner-icon="mdi-key" required/>
              <!-- Repeat Password -->
            <v-text-field label="Confirm your password" 
              v-show="isRecover"
              v-model="resetPassword.confirmPassword"
              :rules="rules.confirmPassword"
              :append-icon="invisibility ? 'mdi-eye-off' : 'mdi-eye'"
              :type="invisibility ? 'password' : 'text'" 
              @click:append="() => (invisibility = !invisibility)"
              solo single-line prepend-inner-icon="mdi-key" required/>
          </v-form>
          <!-- Submit Form -->
          <v-card-actions class="center">
            <v-btn class="submit-button primary" depressed large @click="submit" v-show="!isRecover">
              <v-progress-circular color="white" v-if="progress" indeterminate></v-progress-circular>
              <div class="text-white" v-else>Recover Password</div>
            </v-btn>
            <v-btn class="submit-button primary" depressed large @click="submit" v-show="isRecover">
              <v-progress-circular color="white" v-if="progress" indeterminate></v-progress-circular>
              <div class="text-white" v-else>Update Password</div>
            </v-btn>
          </v-card-actions>
          <!-- Sign Up -->
          <div class="row justify-center sign-in">
            <span>Did you remeber your password ?</span>
            <a @click="changeRoute('/sign-in')">Sign In</a>
          </div>
        </v-card>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      isRecover: false,
      valid: true,
      invisibility: true,    
      resetPassword: {
        email: '',
        password: '',
        confirmPassword: '',
        token: ''
      }, 
      progress: false,
      rules: {
        email: [
          v => {
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            if (this.isRecover) {
              return true
            } else if (v.length) {  
              return pattern.test(v) || 'E-mail invalid!'
            } else {
              return 'E-mail is required!'
            }
          }
        ],
        password: [
          v => {
            const pattern = /^.{6,}$/
            if (!this.isRecover) {  
              return true
            } else if (v.length) {
              if (pattern.test(v)) {
                return true
              } else {
                return 'The password needs at least 6 characters!'
              }
            } else {
              return 'Password is required!'
            }            
          }
        ],
        confirmPassword: [
          v => {
            if (!this.isRecover) {  
              return true
            } else if (v.length) {
              if (v != this.resetPassword.password) {
                return 'The passwords does not match!'
              } else {
                return true
              }
            } else {
              return 'Password is required!'
            }
          }
        ],
      }
    }
  },
  created () {
    const token = this.$route.query.oobCode
    if (token) {
      this.$store.dispatch('verifyRecoveryLink', token).then((response) => {
        if (response.code) {
          this.isRecover = false
        } else {
          this.resetPassword.token = this.$route.query.oobCode
          this.isRecover = true
        }
      })          
    }
  },
  methods: {
    submit() {      
      if (this.$refs.form.validate()) {
        this.progress = true
        const dispatch = this.isRecover ? 'resetPassword' : 'recoveryLink'
        const body = this.isRecover ? {...this.resetPassword} : this.resetPassword.email
        this.$store.dispatch(dispatch, body).then((response) => {
          console.log("RESPONSE:", response )
          this.progress = false
          if (!response) {
            if (dispatch == 'resetPassword') {
              this.isRecover = false
              this.changeRoute('/reset-password')  
            }
          } else {
            this.changeRoute('/sign-in')
          }
        })
      }
    },
    changeRoute (router) {
      this.$router.push(router)
    }
  }
}
</script>

<style scoped src='./style/index.css'/>