<template>
  <v-dialog v-model="dialog.show" max-width="600">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title class="headline">
        Add Shop
      </v-card-title>
      <!-- Shop -->
      <div class="dialog-shop">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field v-model="dialog.shop.shop" label="Shop" suffix=".myshopify.com" outlined :rules="rules.shop"/>
        </v-form>
      </div>
      <!-- Dialog Actions -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="saveShop">
          <v-progress-circular color="white" v-if="progress" indeterminate></v-progress-circular>
          <span v-else>Save</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogAddShop',
  data() {
    return {
      valid: false,
      progress: false,
      rules: {
        shop: [
          v => !!v || 'Shop is required!'
        ]
      }
    }
  },
  computed: {
    dialog() {
      return this.$store.state.GlobalModules.dialogAddShop
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit('closeDialogAddShop')
      this.$refs.form.reset()
    },
    saveShop() {      
      if (this.$refs.form.validate()) {
        const data = {
          id: this.dialog.shop.id,
          shop: this.dialog.shop.shop + '.myshopify.com',
          customerId: this.dialog.shop.shop + '.myshopify.com'
        }
        this.progress = true
        this.$store.dispatch(this.dialog.shop.id ? 'updateShop' : 'addShop', {...data}).then(() => {
          this.closeDialog()
          this.progress = false
        })
      }
    }
  }
}
</script>

<style scoped src='./style/index.css'/>