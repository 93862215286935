import router from '@/router'
import firebase from 'firebase'
import { signUp, signIn, signOut, verifyEmail, 
  recoveryLink, verifyRecoveryLink, resetPassword } from '@/service/firebase'

export default {
  state: {
    isAuthenticated: false
  },
  mutations: {
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    }
  },
  actions: {
    async signIn({commit}, user) {
      const response = await signIn(user)
      if (response.user) {
        if (!firebase.auth().currentUser.emailVerified) {
          firebase.auth().currentUser.sendEmailVerification()
          await signOut()
          return commit('openSnackbar', {message: 'E-mail is not verified, check your email inbox!', timeout: 8000})
        }
        commit('setIsAuthenticated', true)
        router.push('/analytics')
        commit('openSnackbar', {message: 'Welcome!', timeout: 6000})
      } else if (response.code === 'auth/wrong-password' || response.code === 'auth/user-not-found') {
        commit('openSnackbar', {message: 'Wrong e-mail or password!', timeout: 6000})
      } else {
        commit('openSnackbar', {message: 'Something get wrong, try again!', timeout: 6000})
      }
    },
    async signUp({commit}, user) {
      const response = await signUp(user)
       if (response.code === 'auth/email-already-in-use') {
        commit('openSnackbar', {message: 'E-mail already used!', timeout: 6000})
      }
      return response
    },
    async signOut({commit}) {
      await signOut()
      commit('setIsAuthenticated', false)
      router.push('/')
      localStorage.clear()
      commit('openSnackbar', {message: 'Sign out successful!', timeout: 6000})
    },
    async verifyEmail({}, actionCode) {
      const response = await verifyEmail(actionCode)
      return response
    },
    async recoveryLink({commit}, data) {
      const response = await recoveryLink(data)
      if (response && response.code === 'auth/invalid-action-code') {
        commit('openSnackbar', {message: 'Check if the entered e-mail is right!', timeout: 6000})
        return false
      } else {
        commit('openSnackbar', {message: 'Check your e-mail box!', timeout: 6000})
        return true
      }
    },
    async verifyRecoveryLink({commit}, actionCode) {
      const response = await verifyRecoveryLink(actionCode)
      if (response.code) {
        commit('openSnackbar', {message: 'Try recover the password again!', timeout: 6000})        
      }
      return response
    },
    async resetPassword({commit}, data) {
      const response = await resetPassword(data)
      console.log(response)
      if (response && response.code === 'auth/invalid-action-code') {
        commit('openSnackbar', {message: 'Try recover the password again!', timeout: 6000})
        return false
      } else {
        commit('openSnackbar', {message: 'Password updated successfully!', timeout: 6000})
        return true
      }
    }
  }
}