// Models
import auth from './models/auth'
import analytics from './models/analytics'
import importFiles from './models/importFiles'
import orders from './models/orders'
import shops from './models/shops'
import breakdowns from './models/breakdowns'
import presentations from './models/presentations'
import slides from './models/slides'
import user from './models/user'
// Components
import snackbar from './components/snackbar'
import loader from './components/loader'
import globalSelectShop from './components/globalSelectShop'
// Views Components
import dialogFilters from './views/analytics/dialogFilters'
import dialogConfirmStopImport from './views/import/dialogConfirmStopImport'
import importsList from './views/import/importsList'
import dialogAddShop from './views/shops/dialogAddShop'
import dialogArchiveShop from './views/shops/dialogArchiveShop'
import dialogNewPresentation from './views/presentations/dialogNewPresentation'
import dialogNewSlide from './views/slides/dialogNewSlide'

export default {
  modules: {
    // Models
    auth,
    analytics,
    importFiles,
    orders,
    shops,
    breakdowns,
    presentations,
    slides,
    user,
    // Components
    snackbar,
    loader,
    globalSelectShop,
    // Views Components
    dialogFilters,
    dialogConfirmStopImport,
    importsList,
    dialogAddShop,
    dialogArchiveShop,
    dialogNewPresentation,
    dialogNewSlide
  }
}