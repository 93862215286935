<template>
  <div>
    <v-form ref="form" v-model="valid" @keyup.native.enter="saveImport" lazy-validation>
      <v-layout fluid>
        <!-- File Input -->
        <v-flex xs4 class="mx-4">
          <v-file-input v-model="file" color="primary accent-4" counter label="Import File"
            placeholder="Upload" prepend-icon="mdi-paperclip" outlined :show-size="1000" :rules="rules.file">
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                {{text}}
              </v-chip>
              <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
                +{{ file.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
        </v-flex>
        <!-- Type Upload -->
        <v-flex xs4 class="mx-4">
          <v-select v-model="typeUpload" :items="typesUpload" label="Type" outlined required :rules="rules.type"/>
        </v-flex>
      </v-layout>
    </v-form>
    <!-- Save Import -->
    <v-btn rounded color="primary" large class="save-import" @click="saveImport" :disabled="progress">
      <v-progress-circular color="white" v-if="progress" indeterminate></v-progress-circular>
      <div class="text-white" v-else>Save import</div>
    </v-btn>
    <!-- Pending imports -->
    <div class="pending-imports">
      <span class="headline mx-4">
        Pending Imports: 
      </span>
    </div>
    <ImportsList type="pending" class="data-table-pending-imports"/>
    <v-btn text color="primary" large to="import/history" >
      View Import History
    </v-btn>
  </div>
</template>

<script>
import ImportsList from '@/components/views/import/importsList'

export default {
  name: 'Import',
  components: { ImportsList },
  data() {
    return {
      valid: false,
      progress: false,
      file: null,
      typeUpload: '',
      typesUpload: ['Customers', 'Discounts', 'Sales'],
      rules: {
        type: [
          v => !!v || 'Type is required!'
        ],
        file: [
          v => {
            if (v) {
              return true
            } else {
              return 'File is required'
            }
          }
        ]
      }
    }
  },
  created() {
    this.$store.dispatch('getShops')
  },
  computed: {
    shops() {
      return this.$store.state.GlobalModules.shops.shops
    },
    shop: {
      get: function() {
        return this.$store.state.GlobalModules.globalSelectShop.shop
      },
      set: function(newShop) {
        this.$store.commit('setGlobalSelectShop', newShop)
      }
    },
  },
  methods: {
    saveImport() {
      const data = this
      if (this.$refs.form.validate()) {
        data.progress = true
        this.$store.dispatch('importFile', {
          file: this.file, 
          typeUpload: this.typeUpload,
          shop: this.shop
        }).then((response) => { 
          data.progress = false
          if (response) {
            data.$refs.form.reset()
            data.$store.dispatch('getImportsList', {type: 'pending', shop: data.shop})
          }
        })
      }
    }
  }
}
</script>

<style scoped src='./style/index.css'/>