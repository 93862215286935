<template>
  <div>
    <v-btn rounded color="primary" to="/import" style="margin-bottom: 20px">
      <v-icon left dark>
        mdi-arrow-left
      </v-icon>
      back
    </v-btn>
    <ImportsList type="history" class="data-table-pending-imports" />
  </div>
</template>

<script>
import ImportsList from "@/components/views/import/importsList"

export default {
  name: 'ImportHistory',
  components: { ImportsList },
}
</script>