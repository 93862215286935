var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.overlay)?_c('div',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"fluid":""}},[_c('v-flex',{staticClass:"add-shop",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"rounded":"","color":"primary","large":""},on:{"click":_vm.openDialogAddShop}},[_vm._v(" + Add Shop ")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.shops,"page":_vm.page,"no-data-text":"No data","items-per-page":_vm.rowsPerPageSelected,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openDialogAddShop(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.archiveShop(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive")])])]}}],null,false,3941500021)}),(_vm.shops.length === 0)?_c('div',{staticClass:"no-data"},[_vm._v(" No data found ")]):_vm._e(),_c('v-pagination',{staticClass:"pagination",attrs:{"disabled":_vm.loading || _vm.shops.length === 0,"total-visible":7,"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('DialogAddShop'),_c('DialogArchiveShop')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }