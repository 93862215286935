import { getOrder } from '@/service/api/orders'

export default {
  state: {
    order: {}
  },
  mutations: {
    setOrder(state, order) {
      state.order = order
    }
  },
  actions: {
    async getOrder({commit}, {orderId, shop}) {
      const response = await getOrder(orderId, shop)
      if (!response) {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
      if (response.status === 200) {
        commit('setOrder', response.data.orders)
        if (response.data.orders.length === 0) {
          commit('openSnackbar', {message: 'Order not found!', timeout: 6000})     
        }
      } else {
        commit('openSnackbar', {message: response.data.message, timeout: 6000})     
      }
    }
  }
}