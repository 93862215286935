import { getImportsList } from '@/service/api/import-file'

export default {
  state: {
    imports: {},
    loading: false,
    type: ''
  },
  mutations: {
    setImports(state, imports) {
      state.imports = imports
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setType(state, type) {
      state.type = type
    }
  },
  actions: {
    async getImportsList({commit}, {type, shop}) {
      commit('setLoading', true)
      commit('setType', type)
      const response = await getImportsList(type, shop)
      if (response) {
        commit('setImports', response.data.reverse())
      }
      commit('setLoading', false)
    }
  }
}