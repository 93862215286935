<template>
  <div>
    <div style="text-align: end">
      <v-btn text color="primary" @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>
    <v-progress-linear v-show="loading" indeterminate/>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              Date Uploaded
            </th>
            <th>
              File Name
            </th>
            <th>
              Type
            </th>
            <th v-show="type === 'history'">
              Rows
            </th>
            <th class="text-center" v-show="type === 'history'">
              Completed Rows
            </th>
            <th>
              Status
            </th>
            <th class="text-center" v-show="type === 'pending'">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in imports" :key="item._id">
            <td>{{ convertTimestampToDate(item.createdAt) }}</td>
            <td>{{ item.fileName }}</td>
            <td>{{ item.fileType }}</td>
            <td v-show="type ==='history'">{{ item.recordCount }}</td>
            <td class="text-center" v-show="type ==='history'">{{ item.completedRows > 0 ? item.completedRows : 0 }}</td>
            <td>{{ item.status }}</td>
            <td class="text-center" v-show="type === 'pending'">
              <v-btn color="primary" text @click="stopImport(item._id)">
                cancel
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="imports.length === 0" class="no-data">
      No {{type}} imports!
    </div>
    <!-- Dialog Confirm Stop Import -->
    <DialogConfirmStopImport/>
  </div>
</template>

<script>
import { convertTimestampToDate } from '@/utils/date'
import DialogConfirmStopImport from '../dialogConfirmStopImport'

export default {
  name: 'ImportsList',
  components: { DialogConfirmStopImport },
  props: ['type'],
  created() {
    this.$store.dispatch('getImportsList', {type: this.$props.type, shop: this.shop != '' ? this.shop : ''})
  },
  computed: {
    imports() {
      return this.$store.state.GlobalModules.importsList.imports
    },
    loading() {
      return this.$store.state.GlobalModules.importsList.loading
    },
    shop() {
      return this.$store.state.GlobalModules.globalSelectShop.shop
    }
  },
  methods: {
    convertTimestampToDate(date) {
      return convertTimestampToDate(date)
    },
    stopImport(queueId) {
      this.$store.commit('openDialogConfirmStopImports', {shop: this.shop, queueId: queueId})
    },
    refresh() {
      this.$store.dispatch('getImportsList', {type: this.$props.type, shop: this.shop})
    }
  }
}
</script>

<style scoped src='./style/index.css'/>