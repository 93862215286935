<template>
 <div>
    <!-- New Presentation -->
    <v-layout fluid>
      <v-flex xs12 class="add-presentation">
        <v-btn rounded color="primary" large @click="$store.commit('openDialogNewPresentation')">
          <v-icon left>mdi-plus</v-icon>
          New Presentation
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Data -->
    <v-data-table :loading="loading" :headers="headers" :items="presentations" :page.sync="page" no-data-text="No data"
      :items-per-page="25" class="elevation-0" hide-default-footer>
      <!-- slideCounts -->
      <template v-slot:item.slideCounts="{item}">
        {{item.slideCounts ? item.slideCounts : 0}}
      </template>
      <!-- Date Added -->
      <template v-slot:item.dateAdded="{item}">
        {{convertDate(item.dateAdded)}}
      </template>
      <!-- Last Update -->
      <template v-slot:item.lastUpdated="{item}">
        {{convertDate(item.lastUpdated)}}
      </template>
      <!-- View Presentation -->
      <template v-slot:item.view="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" v-bind="attrs" v-on="on" :to="'/presentation/detail/' + item._id">
              <v-icon dark>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>View</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- Pagination -->
    <v-pagination class="pagination" :disabled="loading || presentations.length === 0" v-model="page" :total-visible="7" :length="pageCount"/>
    <!-- Dialog New Presentation -->
    <DialogNewPresentation/>
  </div>
</template>

<script>
import { convertTimestampToDate } from '@/utils/date'

export default {
  name: 'PresentationList',
  components: {
    DialogNewPresentation: () => import('@/components/views/presentations/dialogNewPresentation'),
  },
  data() {
    return {
      page: 1,      
      headers: [
        { 
          text: 'Name', 
          align: 'left',
          value: 'title',
          sortable: false
        },
        { 
          text: 'Slide Count', 
          align: 'center',
          value: 'slideCounts',
          sortable: false
        },
        { 
          text: 'Date Created', 
          align: 'center',
          value: 'dateAdded',
          sortable: false
        },
        { 
          text: 'Last Updated', 
          align: 'center',
          value: 'lastUpdated',
          sortable: false
        },
        { 
          text: 'Created By', 
          align: 'center',
          value: 'createdBy',
          sortable: false
        },
        {
          text: 'View',
          align: 'center',
          value: 'view',
          sortable: false
        }
      ],
    }
  },
  created() {
    if (this.user._id) {
      this.$store.dispatch('getPresentations', this.user._id)
    }
  },
  computed: {
    user() {
      return this.$store.state.GlobalModules.user.user
    },
    presentations() {
      return this.$store.state.GlobalModules.presentations.presentations
    },
    loading() {
      return this.$store.state.GlobalModules.presentations.loading
    },
    pageCount() {
      let page = this.presentations.length > 0 ?
        this.presentations.length / 25 : 1
      return page < 1 ? 1 : Math.ceil(page)
    }
  },
  methods: {
    convertDate(date) {
      return convertTimestampToDate(date)
    }
  },
  watch: {
    user() {
      this.$store.dispatch('getPresentations', this.user._id)
    }
  }
}
</script>

<style scoped src='./style/index.css'/>