<template>
  <div v-if="!overlay" fluid style="display:none;">
    <v-layout fluid>      
      <!-- Breakdown -->
      <v-flex xs4 class="mx-4">
        <v-select v-model="breakdown" :items="breakdownItems.data" item-text="text" item-value="value" label="Breakdown" outlined/>
      </v-flex>
      <!-- Filter Start Date -->
      <v-flex xs2 class="mx-4">
        <v-dialog ref="dialogStartDate" v-model="modalStartDate" :return-value.sync="startDate" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined v-model="dateTextStart" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
          </template>
          <v-date-picker v-model="startDate" scrollable no-title color="light-blue lighten-1" 
            :allowed-dates="allowedDates" @input="$refs.dialogStartDate.save(startDate)"/>          
        </v-dialog>
      </v-flex>
      <!-- Filter End Date -->
      <v-flex xs2 class="mx-4">
        <v-dialog ref="dialogEndDate" v-model="modalEndDate" :return-value.sync="endDate" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined v-model="dateTextEnd" label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
          </template>
          <v-date-picker v-model="endDate" scrollable no-title color="light-blue lighten-1" 
            :allowed-dates="allowedDates" @input="$refs.dialogEndDate.save(endDate)"/>          
        </v-dialog>
      </v-flex>
      <!-- Rows Per Page  -->
      <v-flex xs1 class="mx-4">
        <v-select v-model="rowsPerPageSelected" :items="rowsPerPage" label="Rows Per Page" outlined/>
      </v-flex>
      <!-- Search -->
      <v-flex xs2 class="mx-4 text-right">
        <v-btn depressed color="primary" class="filter-btn" id="search" :disabled="loading" large @click="search">
          Search
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Data -->
    <v-data-table :loading="loading" :headers="headers" :items="breakdowns.data" :page.sync="page" no-data-text="No data"
      :items-per-page="rowsPerPageSelected" class="elevation-0 row-pointer" hide-default-footer>
      <template v-slot:body="{ items }">
        <tbody>
          <!-- Data -->
          <tr v-for="item in items" :key="item.to" v-on:click="onClickRow(breakdown, item)">
            <td class="primary--text">{{ item[breakdown] ? item[breakdown] : '-' }}</td>
            <td>{{ item.total_net_revenue != null ? formatCurrencyValues(item.total_net_revenue) : '-' }}</td>
            <td>{{ item.order_count != null ? item.order_count : '-' }}</td>
            <td>{{ item.new_aov != null ? formatCurrencyValues(item.new_aov) : '-' }}</td>
            <td>{{ item.new_net_revenue != null ? formatCurrencyValues(item.new_net_revenue) : '-' }}</td>
            <td>{{ item.repeat_order_count != null ? item.repeat_order_count : '-' }}</td>
            <td>{{ item.repeat_aov != null ? formatCurrencyValues(item.repeat_aov) : '-' }}</td>
            <td>{{ item.repeat_net_revenue != null ? formatCurrencyValues(item.repeat_net_revenue) : '-' }}</td>
            <td>{{ item.total_cogs != null ? formatCurrencyValues(item.total_cogs) : '-' }}</td>
            <td>{{ item.takehome_net_revenue != null ? formatCurrencyValues(item.takehome_net_revenue) : '-' }}</td>
            <td>{{ item.takehome_net_revenue != null ? formatCurrencyValues(item.takehome_net_revenue) : '-' }}</td>
            <td>{{ item.takehome_net_revenue != null ? formatCurrencyValues(item.takehome_net_revenue) : '-' }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <!-- No data found -->
    <div v-if="breakdowns.data.length === 0" class="no-data">
      No data found
    </div>
    <!-- Pagination -->
    <v-pagination :disabled="loading || breakdowns.data.length === 0" v-model="page" :total-visible="7" :length="pageCount"/>
  </div>
</template>

<script>
import filtersJson from '@/utils/breakdowns/filters'
import analyticsFilters from '@/utils/analytics/filters'

export default {
  name: 'Breakdowns',
  data() {
    return {
      loading: false,
      breakdown: '',
      breakdownItems: filtersJson,
      startDate: null,
      endDate: null,
      modalStartDate: false,
      modalEndDate: false,
      allowedDates: val => {
        let arrayDate = val.split('-')
        let date = new Date([arrayDate[0], arrayDate[1], (arrayDate[2])].join('/'))
        let today = new Date()
        return date < today
      },
      page: 1,
      rowsPerPageSelected: 50,
      rowsPerPage: [25, 50, 100],
      headers: [
        { 
          text: 'Breakdown',
          align: 'left',
          sortable: false
        },
        { 
          text: 'Total Revenue',
          align: 'left',
          sortable: false
        },
        {
          text: 'New Customer',
          align: 'left',
          sortable: false
        },
        { 
          text: 'New AOV', 
          align: 'left',
          sortable: false
        },
        {
          text: 'New Net Revenue',
          align: 'left',
          sortable: false
        }, 
        {
          text: 'Repeat Customers',
          align: 'left',
          sortable: false
        },   
        {
          text: 'Repeat AOV',
          align: 'left',
          sortable: false
        },   
        { 
          text: 'Repeat Net Revenue',
          align: 'left',
          sortable: false
        },   
        { 
          text: 'COGS',
          align: 'left',
          sortable: false
        },
        { 
          text: 'New Takehome',
          align: 'left',
          sortable: false
        },
        { 
          text: 'Repeat Takehome',
          align: 'left',
          sortable: false
        },
        { 
          text: 'Total Takehome',
          align: 'left',
          sortable: false
        }
      ],
    }
  },
  created() {
    this.$store.commit('setGlobalSelectShop', 
      localStorage.getItem('shopFilter') ? localStorage.getItem('shopFilter') : '')
    this.$store.commit('clearBreakdowns')
    const url = new URL(document.location.href)
    const date = new Date()
    date.setDate(date.getDate() -7)
    this.breakdown = url.searchParams.get('breakdown') ? url.searchParams.get('breakdown') : ''
    this.startDate = url.searchParams.get('startDate') ? url.searchParams.get('startDate') : 
      (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.endDate = url.searchParams.get('endDate') ? url.searchParams.get('endDate') : 
      (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.rowsPerPageSelected = url.searchParams.get('rowsPerPageSelected') ? parseInt(url.searchParams.get('rowsPerPageSelected')) : 50
    this.search()
  },
  computed: {
    breakdowns() {
      return this.$store.state.GlobalModules.breakdowns.breakdowns
    },
    overlay() {
      return this.$store.state.GlobalModules.loader.overlay
    },
    dateTextStart() {
      return this.dateText(this.startDate)
    },
    dateTextEnd() {
      return this.dateText(this.endDate)
    },
    pageCount() {
      let page = this.$store.state.GlobalModules.breakdowns.breakdowns.totalRecords > 0 ?
        this.$store.state.GlobalModules.breakdowns.breakdowns.totalRecords / this.rowsPerPageSelected : 1
      return page < 1 ? 1 : Math.ceil(page)
    },
    shop() {
      return this.$store.state.GlobalModules.globalSelectShop.shop
    }
  },
  methods: {
    search() {
      if (this.shop === '') {
        return this.$store.commit('openSnackbar', {message: 'Please select a shop filter!', timeout: 6000})
      }
      if (this.breakdown === '') {
        return this.$store.commit('openSnackbar', {message: 'Please select a breakdown filter!', timeout: 6000})
      }
      this.loading = true
      this.$store.dispatch('getBreakdowns', {load: false, startDate: this.startDate, endDate: this.endDate, 
        breakdown: this.breakdown, count: this.rowsPerPageSelected, page: this.page, shop: this.shop}).then(() => {
        this.loading = false
      })
    },
    onClickRow(breakdown, item) {
      if (analyticsFilters.data.find(item => item.value === breakdown) && item[breakdown]) {        
        this.$store.commit('clearFilters')
        this.$router.push({name: 'Analytics', 
          query: {
            shop: this.shop,
            viewBy: 'Day',
            dateFrom: this.startDate,
            dateTo: this.endDate,
            rowsPerPageSelected: this.rowsPerPageSelected,
            aditionalFilters: encodeURI(analyticsFilters.data.find(item => item.value === breakdown).text + ' is ' + item[breakdown])
          }
        })
      }
    },
    setFilters() {
      let filter = '?shop=' + this.shop + '&breakdown=' + this.breakdown + '&startDate=' + this.startDate + '&endDate=' + this.endDate + '&rowsPerPageSelected=' + this.rowsPerPageSelected
      const setFilter = window.location.protocol + "//" + window.location.host + window.location.pathname + filter
      window.history.pushState({ path: setFilter }, '', setFilter)
    },
    dateText(date) {
      if (date) {
        let dateSplitted = date.split('-')      
        return dateSplitted[1] + '/' + dateSplitted[2] + '/' + dateSplitted[0]
      }
    },
    formatCurrencyValues(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
    },
  },
   watch: {
    page() {
      this.search()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    rowsPerPageSelected() {
      if (!this.loading) {
        this.page = 1
        this.setFilters()
        if (this.breakdown == '') { return }
        this.search()
      }
    },
    breakdown() {
      if (!this.loading) {
        this.setFilters()
        this.search()
      }
    },
    startDate() {
      this.setFilters()
    },
    endDate() {
      this.setFilters()
    }
  }
}
</script>

<style scoped>

  .no-data {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
  }

  .filter-btn {
    margin: 5px 0px;
    min-width: 150px !important;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

</style>