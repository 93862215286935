export default {
  state: {
    overlay: false
  },
  mutations: {
    setLoader(state, payload) {
      state.overlay = payload
    }
  }
}
