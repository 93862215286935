<template>
  <v-dialog v-model="dialog.show" max-width="600">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title class="headline">
        Add Filter
      </v-card-title>
      <!-- Filter -->
      <div class="dialog-filter">
        <v-form ref="form" v-model="valid" @keyup.native.enter="saveFilters" lazy-validation>
          <!-- Filter Type -->
          <v-select v-model="type" :items="items.data" item-text="text" item-value="value" label="Type" outlined :rules="rules.type"/>
          <!-- Order Value Filter -->
          <v-radio-group v-if="type === 'orderValue'" v-model="orderValue" row class="order-value-filter">
            <v-radio label="More than" value="greater-than"/>
            <v-radio label="Less than" value="less-than"/>
          </v-radio-group>
          <!-- Order Value -->
          <v-text-field v-if="type === 'orderValue'" v-model="text" label="Filter" outlined :rules="rules.filter"/>
          <!-- Is Discount Used -->
          <v-switch v-else-if="type === 'isDiscountUsed'" v-model="isDiscountUsed" :label="'Is Discount Used? ' + (isDiscountUsed ? 'Yes':'No')"/>
          <!-- Filter Text -->
          <v-autocomplete v-else v-model="filter" :disabled="filterLoading" :loading="filterLoading" :items="filtersItems" :label="getItemText(type)" outlined :rules="rules.filter"/>
        </v-form>
      </div>
      <!-- Dialog Actions -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="saveFilters">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import breakdownsItems from '@/utils/breakdowns/filters'
import filtersJson from '@/utils/analytics/filters'

export default {
  name: 'DialogFilters',
  data() {
    return {
      valid: false,
      type: '',
      text: '',
      orderValue: 'greater-than',
      filter: '',
      filtersItems: [],
      filterLoading: false,
      isDiscountUsed: false,
      items: filtersJson,
      rules: {
        type: [
          v => !!v || 'Type is required!'
        ],
        filter: [
          v => !!v || 'Filter is required!'
        ]
      }
    }
  },
  computed: {
    dialog() {
      return this.$store.state.GlobalModules.dialogFilters
    },
    shop() {
      return this.$store.state.GlobalModules.globalSelectShop.shop
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit('closeDialogFilters')
      this.$refs.form.reset()
      this.orderValue = 'greater-than'
    },
    saveFilters() {      
      if (this.$refs.form.validate()) {
        let filter = {}
        let itemText = this.getItemText(this.type) 
        filter.type = this.type
        filter.value = encodeURI(this.getFilterValue())
        filter.text = this.type === 'isDiscountUsed' ? 'Is Discount Used? ' + (this.isDiscountUsed ? 'Yes':'No') : encodeURI(itemText + ' is ' + decodeURI(filter.value))
        let isFilterAlreadyApplied = this.checkIfFilterIsAlreadyApplied(filter.type, filter.value)
        if (!isFilterAlreadyApplied) {
          this.$store.commit('addFilter', filter)
          this.closeDialog()
        } else {
          this.$store.commit('openSnackbar', {message: 'This filter is already applied!', timeout: 6000})
        }
      }
    },
    getFilterValue() {
      switch (this.type) {
        case 'orderValue':
          return (this.orderValue === 'greater-than' ? 'more' : 'less') + ' than ' + this.text
        case 'isDiscountUsed':
          return this.isDiscountUsed
        default:
          return this.filter
      }
    },
    checkIfFilterIsAlreadyApplied(type, value) {
      return type === 'state' || type === 'country' || type === 'orderValue' ? 
        this.dialog.filters.find(item => item.type.toLowerCase() === type.toLowerCase() && item.value.toLowerCase() === value.toLowerCase()) != undefined :
        this.dialog.filters.find(item => item.type.toLowerCase() === type.toLowerCase()) != undefined
    },
    getItemText(type) {
      if (!type) { return }
      return this.items.data.find(item => item.value === type).text
    }
  },
  watch: {
    type() {
      if (!this.type) { return }
      if (breakdownsItems.data.find(item => item.value === this.type) != undefined) {
        this.filterLoading = true
        this.$store.dispatch('getFilterItems', {filterType: this.type, shop: this.shop}).then((response) => {
          this.filterLoading = false
          this.filtersItems = response.data
        })
      }
    }
  }
}
</script>

<style scoped src='./style/index.css'/>