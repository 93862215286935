import firebase from 'firebase'
import axios from 'axios'
import { user, userSignUp, saveShopifyTokenAPI } from "@/config/api"

// Post User
export const postUser = async (data) => {
  try {
    const response = await axios.post(`${userSignUp}`, JSON.stringify(data))
    return response.data
  } catch (error) {
    return error
  }
}

// Get User
export const getUser = async () => {
  try {
    const response = await axios.get(`${user}?userId=${firebase.auth().currentUser.uid}`)
    return response.data
  } catch (error) {
    return error
  }
}

// Save Shopify token
export const saveShopifyToken = async (data) => {
  try {
    const response = await axios.post(`${saveShopifyTokenAPI}`, JSON.stringify(data))
    return response.data
  } catch (error) {
    return error
  }
}