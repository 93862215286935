import { getBreakdowns } from '@/service/api/breakdowns'

export default {
  state: {
    breakdowns: {
      data: [],
      totalRecords: ''
    }
  },
  mutations: {
    setBreakdowns(state, breakdowns) {
      state.breakdowns.data = breakdowns.result
      state.breakdowns.totalRecords = breakdowns.totalRecords
    },
    clearBreakdowns(state) {
      state.breakdowns = {
        totalRecords: 0,
        data: []
      }
    }
  },
  actions: {
    async getBreakdowns({commit}, {load, startDate, endDate, breakdown, count, page, shop}) {
      commit('setLoader', load)
      const response = await getBreakdowns(startDate, endDate, breakdown, count, page, shop)
      if (!response) {
        return commit('openSnackbar', {message: 'Something get wrong!', timeout: 6000})     
      }
      if (response.status === 200) {
        commit('setBreakdowns', response.data.data)
      } else {
        commit('openSnackbar', {message: response.data.message, timeout: 6000})
      }
      commit('setLoader', false)
    }
  }
}