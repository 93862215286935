import firebase from 'firebase'
import axios from 'axios'
import { getOrderById } from "@/config/api"

// Get Order By Id
export const getOrder = async (orderId, shop) => {
  try {
    const response = await axios.get(`${getOrderById}?accessToken=${firebase.auth().currentUser.uid}&order_id=${orderId}&shop=${shop}`)
    return response
  } catch (error) {
    return error.response
  }
}