<template>
  <div v-if="!overlay" fluid style="display:none;">
    <v-layout fluid>
      <!-- View By -->
      <v-flex xs3 class="mx-4">
        <v-select v-model="viewBy" :items="items" label="View By" outlined @change="search"/>
      </v-flex>
      <!-- Filter Date From -->
      <v-flex xs3 class="mx-4">
        <v-dialog ref="dialogDateFrom" v-model="modalDateFrom" :return-value.sync="dateFrom" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined v-model="dateTextFrom" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
          </template>
          <v-date-picker v-model="dateFrom" scrollable no-title color="light-blue lighten-1" 
            :allowed-dates="allowedDates" @input="$refs.dialogDateFrom.save(dateFrom)"/>
        </v-dialog>
      </v-flex>
      <!-- Filter Date To -->
      <v-flex xs3 class="mx-4">
        <v-dialog ref="dialogDateTo" v-model="modalDateTo" :return-value.sync="dateTo" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined v-model="dateTextTo" label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
          </template>
          <v-date-picker v-model="dateTo" scrollable no-title color="light-blue lighten-1" 
            :allowed-dates="allowedDates" @input="$refs.dialogDateTo.save(dateTo)"/>
        </v-dialog>
      </v-flex>
      <!-- Rows Per page -->
      <v-flex xs1 class="mx-4">
        <v-select v-model="rowsPerPageSelected" :items="rowsPerPage" label="Rows Per Page" outlined/>
      </v-flex>
      <!-- Search -->
      <v-flex xs2 class="mx-4 text-right">
        <v-btn depressed color="primary" :disabled="loading" large class="filter-btn" id="search" @click="search">
          Search
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Filters -->
    <v-layout fluid>
      <v-flex xs10 class="mx-4 my-3">
        <span class="headline mb-1">
          Filters: 
        </span>
        <v-chip class="ma-2" close color="primary" v-for="filter in filters" :key="filter.text"
          text-color="white" @click:close="removeFilter(filter)">
          {{decodeURI(filter.text)}}
        </v-chip>
      </v-flex>
      <v-flex xs2 class="mx-4 text-right">
        <v-btn depressed color="primary" large class="filter-btn" @click="openDialogFilters">
          + Add Filter
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Summary -->
    <div class="summary" v-if="Object.keys(analytics.data_summary).length > 0"> 
      <span class="headline mb-1">
        Summary
      </span>
      <v-layout fluid class="summary-list text-center">
        <!-- Net Sales -->
        <v-flex xs3 class="mx-4">
          <div>
            {{formatCurrencyValues(analytics.data_summary.total_net_revenue)}}
          </div>
          Net Sales
        </v-flex>
        <!-- Take Home -->
        <v-flex xs3 class="mx-4">
          <div>
            {{formatCurrencyValues(analytics.data_summary.takehome_net_revenue)}}
          </div>
          Take Home
        </v-flex>
        <!-- Total Orders -->
        <v-flex xs3 class="mx-4">
          <div>
            {{analytics.data_summary.order_count}}
          </div>
          Total Orders
        </v-flex>
        <!-- New Customers -->
        <v-flex xs3 class="mx-4">
          <div>
            {{analytics.data_summary.new_order_count}}
          </div>
          New Customers
        </v-flex>
      </v-layout>
    </div>
    <!-- Data -->
    <v-data-table :loading="loading" :headers="headers" :items="analytics.data" :page.sync="page" no-data-text="No data"
      :items-per-page="rowsPerPageSelected" class="elevation-0" hide-default-footer>
      <template v-slot:body="{ items }">
        <tbody>
          <!-- Summary -->
          <tr>
            <td>Summary</td>
            <td>{{ analytics.data_summary.new_order_count != null ? analytics.data_summary.new_order_count : '-' }}</td>
            <td>{{ analytics.data_summary.new_aov != null ? formatCurrencyValues(analytics.data_summary.new_aov) : '-' }}</td>
            <td>{{ analytics.data_summary.new_net_revenue != null ? formatCurrencyValues(analytics.data_summary.new_net_revenue) : '-' }}</td>
            <td>{{ analytics.data_summary.repeat_order_count != null ? analytics.data_summary.repeat_order_count : '-' }}</td>
            <td>{{ analytics.data_summary.repeat_aov != null ? formatCurrencyValues(analytics.data_summary.repeat_aov) : '-' }}</td>
            <td>{{ analytics.data_summary.repeat_net_revenue != null ? formatCurrencyValues(analytics.data_summary.repeat_net_revenue) : '-' }}</td>
            <td>{{ analytics.data_summary.order_count != null ? analytics.data_summary.order_count : '-' }}</td>
            <td>{{ analytics.data_summary.aov != null ? formatCurrencyValues(analytics.data_summary.aov) : '-' }}</td>
            <td>{{ analytics.data_summary.total_net_revenue != null ? formatCurrencyValues(analytics.data_summary.total_net_revenue) : '-' }}</td>
            <td>{{ analytics.data_summary.total_cogs != null ? formatCurrencyValues(analytics.data_summary.total_cogs) : '-' }}</td>
            <td>{{ analytics.data_summary.takehome_net_revenue != null ? formatCurrencyValues(analytics.data_summary.takehome_net_revenue) : '-' }}</td>
          </tr>
          <!-- Data -->
          <tr v-for="item in items" :key="item.to">
            <td>{{ formatDate(item.from) }} <br> {{ formatDate(item.to) }}</td>
            <td>{{ item.data.new_order_count != null ? item.data.new_order_count : '-' }}</td>
            <td>{{ item.data.new_aov != null ? formatCurrencyValues(item.data.new_aov) : '-' }}</td>
            <td>{{ item.data.new_net_revenue != null ? formatCurrencyValues(item.data.new_net_revenue) : '-' }}</td>
            <td>{{ item.data.repeat_order_count != null ? item.data.repeat_order_count : '-' }}</td>
            <td>{{ item.data.repeat_aov != null ? formatCurrencyValues(item.data.repeat_aov) : '-' }}</td>
            <td>{{ item.data.repeat_net_revenue != null ? formatCurrencyValues(item.data.repeat_net_revenue) : '-' }}</td>
            <td>{{ item.data.order_count != null ? item.data.order_count : '-' }}</td>
            <td>{{ item.data.aov != null ? formatCurrencyValues(item.data.aov) : '-' }}</td>
            <td>{{ item.data.total_net_revenue != null ? formatCurrencyValues(item.data.total_net_revenue) : '-' }}</td>
            <td>{{ item.data.total_cogs != null ? formatCurrencyValues(item.data.total_cogs) : '-' }}</td>
            <td>{{ item.data.takehome_net_revenue != null ? formatCurrencyValues(item.data.takehome_net_revenue) : '-' }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <!-- No data found -->
    <div v-if="analytics.data.length === 0" class="no-data">
      No data found
    </div>
    <!-- Pagination -->
    <v-pagination class="pagination" :disabled="loading || analytics.data.length === 0" v-model="page" :total-visible="7" :length="pageCount"/>
    <!-- Dialog Filters -->
    <DialogFilters/>
  </div>
</template>

<script>
import filtersJson from '@/utils/analytics/filters'
import DialogFilters from '@/components/views/analytics/dialogFilters'

export default {
  name: 'Analytics',
  components: { DialogFilters },
  data() {
    return {
      page: 1,
      viewBy: 'Day',
      items: ['Hour', 'Day', 'Week', 'Month'],
      dateFrom: '',
      dateTo: '',
      modalDateFrom: false,
      modalDateTo: false,
      loading: false,
      page: 1,
      rowsPerPageSelected: 50,
      rowsPerPage: [25, 50, 100],
      headers: [
        { 
          text: 'Date', 
          align: 'left',
          value: 'to',
          sortable: false
        },
        {
          text: 'New Customer Count',
          align: 'left',
          sortable: false
        },
        { 
          text: 'New Customer AOV', 
          align: 'left',
          sortable: false
        },
        {
          text: 'New Customer Revenue',
          align: 'left',
          sortable: false
        }, 
        {
          text: 'Repeat Order Count',
          align: 'left',
          sortable: false
        },   
        {
          text: 'Repeat Order AOV',
          align: 'left',
          sortable: false
        },   
        { 
          text: 'Repeat Revenue',
          align: 'left',
          sortable: false
        },   
        { 
          text: 'Total Orders',
          align: 'left',
          sortable: false
        },
        { 
          text: 'Total AOV',
          align: 'left',
          sortable: false
        },
        { 
          text: 'Total Revenue',
          align: 'left',
          sortable: false
        },
        { 
          text: 'Total Cogs',
          align: 'left',
          sortable: false
        },
        { 
          text: 'Take Home Net Revenue',
          align: 'left',
          sortable: false
        }
      ],
      allowedDates: val => {
        let arrayDate = val.split('-')
        let date = new Date([arrayDate[0], arrayDate[1], (arrayDate[2])].join('/'))
        let today = new Date()
        return date < today
      }
    }
  },
  created() {
    this.$store.commit('setGlobalSelectShop', 
      localStorage.getItem('shopFilter') ? localStorage.getItem('shopFilter') : '')
    this.$store.commit('clearAnalytics')
    const url = new URL(document.location.href)
    const date = new Date()
    date.setDate(date.getDate() -7)
    // Date Range Filters
    const dateRange = {
      from: url.searchParams.get('dateFrom') ? url.searchParams.get('dateFrom') : 
        (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      to: url.searchParams.get('dateTo') ? url.searchParams.get('dateTo') : 
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      viewBy: url.searchParams.get('viewBy') ? url.searchParams.get('viewBy') : 'Day',
      page: 1,
      rowsPerPageSelected: url.searchParams.get('rowsPerPageSelected') ? parseInt(url.searchParams.get('rowsPerPageSelected')) : 50
    }
    if (this.shop === '') {
      this.$store.commit('setGlobalSelectShop', url.searchParams.get('shop') ? url.searchParams.get('shop') : '')
    }
    this.dateFrom = dateRange.from
    this.dateTo = dateRange.to
    this.viewBy = dateRange.viewBy
    this.rowsPerPageSelected = dateRange.rowsPerPageSelected
    // Aditional Filters
    let aditionalFilters = url.searchParams.get('aditionalFilters') ? url.searchParams.get('aditionalFilters').split(';') : []
    if (aditionalFilters.length > 0) {
      for (let i = 0; i < aditionalFilters.length; i++) {
        let data = decodeURI(aditionalFilters[i]).split(' is ')
        let filter = {}
        if (data.length > 1) {
          filter = {
            type: this.getFilterType(data[0]),
            text: aditionalFilters[i],
            value: encodeURI(data[1].trim())
          }
        } else {
          if (aditionalFilters[i].includes('Discount Used')) {
            data = aditionalFilters[i].split('Is Discount Used?')
            filter = {
              type: 'isDiscountUsed',
              text: aditionalFilters[i],
              value: data[1].includes('Yes') ? true : false
            }
          }
        }
        if (filter.type) {
          this.$store.commit('addFilter', filter)
        }
      }
    }
    this.search()
  },
  computed: {
    shop() {
      return this.$store.state.GlobalModules.globalSelectShop.shop
    },
    overlay() {
      return this.$store.state.GlobalModules.loader.overlay
    },
    analytics() {
      return this.$store.state.GlobalModules.analytics.analytics
    },
    pageCount() {
      let page = this.$store.state.GlobalModules.analytics.analytics.totalOrders > 0 ?
        this.$store.state.GlobalModules.analytics.analytics.totalOrders / this.rowsPerPageSelected : 1
      return page < 1 ? 1 : Math.ceil(page)
    },
    dateTextFrom() {
      return this.dateText(this.dateFrom)
    },
    dateTextTo() {
      return this.dateText(this.dateTo)
    },
    filters() {
      return this.$store.state.GlobalModules.dialogFilters.filters
    }
  },
  methods: {
    search() {
      if (this.shop === '') {
        return this.$store.commit('openSnackbar', {message: 'Please select a shop filter!', timeout: 6000})
      }
      let dateRange = {
        from: this.dateFrom,
        to: this.dateTo,
        viewBy: this.viewBy.toLowerCase(),
        page: this.page,
        rowsPerPageSelected: this.rowsPerPageSelected
      }
      this.loading = true
      this.$store.dispatch('getAllAnalytics', {load: false, dateRange: dateRange, shop: this.shop, filters: this.filters}).then(() => {
        this.loading = false
      })
    },
    openDialogFilters() {
      this.$store.commit('openDialogFilters')
    },
    removeFilter(filter) {
      this.$store.commit('removeFilter', filter)
    },
    dateText(date) {
      let dateSplitted = date.split('-')      
      return dateSplitted[1] + '/' + dateSplitted[2] + '/' + dateSplitted[0]
    },
    formatDate(date) {
      let data = date.split(' ')
      let formatDate = '-'
      if (date) {
        let fullDate = data[0].split('-')
        formatDate = fullDate[1] + '/' + fullDate[2] + '/' + fullDate[0]
        if (data.length > 1) {
          formatDate += ' - ' + data[1]
        }
      }
      return formatDate
    },
    formatCurrencyValues(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
    },
    setFilters() {
      let aditionalFilters = ''
      for (let i = 0; i < this.filters.length; i++) {
        aditionalFilters += this.filters[i].text + ';'
      }
      let filter = '?shop=' + this.shop + '&viewBy=' + this.viewBy + '&dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo + '&rowsPerPageSelected=' + this.rowsPerPageSelected + '&aditionalFilters=' + aditionalFilters
      const setFilter = window.location.protocol + "//" + window.location.host + window.location.pathname + filter
      window.history.pushState({ path: setFilter }, '', setFilter)
    },
    getFilterType(text) {
      return filtersJson.data.find(item => item.text === text).value
    }
  },
  watch: {
    page() {
      this.search()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    filters() {     
      if (!this.overlay) {
        this.search()
        this.setFilters()
      } 
    },
    rowsPerPageSelected() {
      this.page = 1
      this.search()
    },
    shop() {
      this.setFilters()
    },
    viewBy() {
      this.setFilters()
    },
    dateFrom() {
      this.setFilters()
    },
    dateTo() {
      this.setFilters()
    },
    rowsPerPageSelected() {
      this.setFilters()
    }
  }
}
</script>

<style scoped src='./style/index.css'/>