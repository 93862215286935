<template>
  <v-container class="fill-height sign-up-card" fluid>
    <v-row justify="center">
      <v-flex>
        <v-card class="mx-auto elevation-0" max-width="400">
          <!-- Logo -->
          <v-card-title class="logo">
            <img src="@/assets/logos/logo.png" height="100">
          </v-card-title>
          <!-- Form -->
          <v-form class="form" ref="signUpForm" v-model="valid" lazy-validation>
            <!-- Name -->
            <v-text-field label="First Name" v-model="user.firstName" 
              :rules="rules.required" solo single-line required/>
            <!-- Last Name -->
            <v-text-field label="Last Name" v-model="user.lastName" 
              :rules="rules.required" solo single-line required/>
            <!-- Company -->
            <v-text-field label="Company" v-model="user.company" 
              :rules="rules.required" solo single-line required/>
            <!-- Email -->
            <v-text-field label="E-mail" v-model="user.email" :rules="rules.email"              
              @keypress="blockWhiteSpace" solo single-line required/>
            <!-- Password -->
            <v-text-field label="Password" 
              v-model="user.password"
              :rules="rules.password"
              :append-icon="invisibility ? 'mdi-eye-off' : 'mdi-eye'"
              :type="invisibility ? 'password' : 'text'"
              @click:append="() => (invisibility = !invisibility)"
              solo single-line required/>
            <!-- Repeat Password -->
            <v-text-field label="Confirm your password" 
              v-model="user.confirmPassword"
              :rules="rules.confirmPassword"
              :append-icon="invisibility ? 'mdi-eye-off' : 'mdi-eye'"
              :type="invisibility ? 'password' : 'text'"
              @click:append="() => (invisibility = !invisibility)"
              solo single-line required/>
          </v-form>
          <!-- Submit Form -->
          <v-card-actions class="center">
            <v-btn class="submit-button primary" depressed large @click="submit" :loading="progress" :disabled="disableSignUp">
              Sign Up
            </v-btn>
          </v-card-actions>
          <!-- Sign In -->
          <div class="row justify-center sign-in">
            <span>Do you have an account ?</span>
            <a @click="changeRoute('/sign-in')">Sign In</a>
          </div>
        </v-card>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SignUp',
  data() {
    return {
      valid: true,
      invisibility: true,    
      user: {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      disableSignUp: false,
      progress: false,
      rules: {
        required: [
          v => !!v || 'Field is required!'
        ],
        email: [
          v => {
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            if (v.length) {
              return pattern.test(v) || 'E-mail invalid!'
            } else {
              return 'E-mail is required!'
            }
          }
        ],
        password: [
          v => {
            const pattern = /^.{6,}$/
            if (v.length) {
              if (pattern.test(v)) {
                return true
              } else {
                return 'The password needs at least 6 characters!'
              }
            } else {
              return 'Password is required!'
            }
          }
        ],
        confirmPassword: [
          v => {
            if (v.length) {
              if (v != this.user.password) {
                return 'The passwords does not match!'
              } else {
                return true
              }
            } else {
              return 'Password is required!'
            }
          }
        ]
      },
    }
  },
  created() {
    this.disableSignUp = !localStorage.getItem('shopify')
    if (this.disableSignUp) {
      this.$store.commit('openSnackbar', {message: 'A shopify invite is needed to sign up!', timeout: 10000})
    }
  },
  methods: {
    blockWhiteSpace() {
      if (event.keyCode == 32) {
        event.preventDefault();
      }
    },
    submit() {
      if (this.$refs.signUpForm.validate()) {
        this.progress = true
        this.$store.dispatch('signUp', {...this.user}).then((response) => {
          if (response.user) {
            this.user.authToken = response.user.uid
            this.user.userId = response.user.uid
            if (localStorage.getItem('shopify')) {
              this.user.token = localStorage.getItem('shopify')
            }         
            delete this.user.password
            delete this.user.confirmPassword
            localStorage.removeItem('shopify')
            this.$store.dispatch('postUser', {...this.user}).then(() => {
              this.progress = false
              this.$router.push('/')
            })
          } else {
            this.progress = false
          }
        })
      }
    },
    changeRoute (router) {
      this.$router.push(router)
    }
  }
}
</script>

<style scoped src='./style/index.css'/>