import { getUser, postUser, saveShopifyToken } from '@/service/api/user'

export default {
  state: {
    user: {},
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
    async getUser({commit}) {
      const response = await getUser()
      if (response.message === 'SUCCESS') {
        commit('setUser', response.user)
      }
    },
    async postUser({commit}, user) {
      const response = await postUser(user)
      if (response.message === 'SUCCESS') {
        commit('openSnackbar', {message: 'Sign up successful, confirm you e-mail address!', timeout: 6000})
      }
    },
    async saveShopifyToken({}, shopifyToken) {
      const response = await saveShopifyToken(shopifyToken)
      return response
    }
  }
}