import S3 from 'aws-s3'
import { config } from '@/config/s3-bucket'

// Import Files to S3 Bucket on AWS
export const importFileS3 = async (file) => {
  try {
    const S3Client = new S3(config);
    const response = S3Client.uploadFile(file, new Date().getTime() + '-' + file.name)
    return response
  } catch (error) {
    return error
  }
}