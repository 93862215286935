import firebase from 'firebase'
import axios from 'axios'
import { importFile, stopImport, pendingImports } from "@/config/api"

// Import File
export const importFileAPI = async (data) => {
  try {
    const response = await axios.post(`${importFile}`, JSON.stringify(data))
    return response
  } catch (error) {
    return error
  }
}

// Stop Import
export const stopImportQueue = async (shop, queueId) => {
  try {
    const response = await axios.get(`${stopImport}?shop=${shop}&accessToken=${firebase.auth().currentUser.uid}&queueId=${queueId}`)
    return response
  } catch (error) {
    return error
  }
}

// Get Imports List
export const getImportsList = async (type, shop) => {
  try {
    const status = type === 'pending' ? '&status=pending' : ''
    const response = await axios.get(`${pendingImports}?shop=${shop}&accessToken=${firebase.auth().currentUser.uid}${status}`)
    return response.data
  } catch (error) {
    return error
  }
}